<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por lg:w-70por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
            <Request model="Area" action="byId" :form="areaFilter" @success="onSuccessGetArea" @error="onErrorGetArea" ref="getAreaRef"/>
            <div class="flex flex-col h-full">
                <ModalHeader :type="'show'" :editable="editable" :entity="entity" @close="show = false"/>
                <div class="flex flex-col justify-center items-center w-full p-4 md:px-20">
                    <label class="mt-1 w-full max-w-lg">
                        <span>Nombre</span>
                        <input class="form-input mt-1 w-full text-center text-bo-input" :class="{'bg-gray-200 noselect' : !editable}"
                               placeholder="Nombre" v-model="form.name" ref="nameRef" :disabled="!editable" required>
                    </label>
                    <div class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
                        <label class="w-auto" for="projected_customers_target">
                            <span>Objetivo clientes proyectado</span>
                        </label>
                        <input type="number" min="0" step="1" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input" :class="{'bg-gray-200 noselect' : !editable}"
                                placeholder="Objetivo" v-model="form.projected_customers_target" id="projected_customers_target" :disabled="!editable" required>
                    </div>
                    <p class="mt-4 w-full text-center">Oportunidades Plus</p>
                    <RadioBinarySelector :parentValue="form.has_opportunities_plus" :editable="editable" @change="hasOpportunitiesPlusChanged"/>
                    <transition name="slide-fade">
                        <div v-show="form.has_opportunities_plus" class="mt-4 flex flex-row justify-between items-center w-full max-w-lg">
                            <label class="w-auto" for="projected_opportunities_plus_target">
                                <span>Objetivo oportunidades plus proyectado</span>
                            </label>
                            <input type="number" min="0" step="1" class="form-input mt-1 w-32 lg:w-40 text-center text-bo-input"
                                placeholder="Objetivo" v-model="form.projected_opportunities_plus_target" id="projected_opportunities_plus_target"
                                ref="projectedOpportunitiesPlusTargetRef" :disabled="!editable">
                        </div>
                    </transition>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="Area" action="update" :form="form" @success="onSuccess" @error="onError" ref="updateAreaRef"/>
                <ActionBtn v-show="!editable" :action="'edit'" @edit="edit"/>
                <ActionBtn v-show="editable" :action="'store'" @store="store"/>
            </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
import {actions} from "@/store";
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";
import RadioBinarySelector from "@/pages/inside/shared/modals/RadioBinarySelector";

export default {
    name: "Show",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter, ActionBtn, RadioBinarySelector
    },
    props: ['entity'],
    data() {
        return {
            show: false,
            editable: false,
            form: {
                id: null,
                name: null,
                projected_customers_target: null,
                projected_opportunities_plus_target: null,
                has_opportunities_plus: null
            },
            areaFilter: {
                id: null
            }
        }
    },
    watch: {
        show(n) {
            if (n) {
                this.areaFilter.id = this.elSelected.id;
                this.$refs.getAreaRef.request();
                this.$refs.nameRef.disabled = true;
            } else {
                this.editable = false;
            }
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        }
    },
    methods: {
        edit(){
            this.editable = !this.editable;
            if (this.editable){
                this.$refs.nameRef.disabled = false;
                this.$refs.nameRef.focus();
            }
        },
        store() {
            this.$refs.updateAreaRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
            this.$emit('refreshDataTable');
        },
        onSuccessGetArea(area) {
            this.setForm(area);
        },
        onErrorGetArea(errors) {
            this.toastErrors(errors)
        },
        setForm(area) {
            this.form.id  = area.id;
            this.form.name = area.name;
            this.form.projected_customers_target = area.projected_customers_target;
            this.form.projected_opportunities_plus_target = area.projected_opportunities_plus_target;
            this.form.has_opportunities_plus = area.has_opportunities_plus;
        },
        hasOpportunitiesPlusChanged(value) {
            this.form.has_opportunities_plus = value !== "NO";
            if (value === "NO") {
                this.form.projected_opportunities_plus_target = null;
            } else {
                this.$refs.projectedOpportunitiesPlusTargetRef.focus();
            }
        }
    },
}
</script>

<style scoped>

</style>