<template>
    <All model="Area" :immediate="true" ref="areasRef" v-slot="{data:areas}" :query="areasFilter" @loading:change="(event) => loading = event">
        <SectionWrapper>
            <div class="w-full px-1 sm:px-2 xl:px-5">
                <Header :title="title"/>
                <div class="py-2 w-full flex flex-row items-between justify-center">
                    <perPageSelector @changeOption="changePerPage"/>
                    <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
                        <new-mod :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <show-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <destroy-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                    </div>
                    <searcher @changeText="changeText"/>
                </div>
                <Table
                    :data="areas.data" :fields="fields" :fieldBy="areasFilter.field"
                    :direction="areasFilter.direction" :loading="loading"
                    @changeField="changeField" @changeDirection="changeDirection">

                    <template v-slot:customers_target_percent_reached="{ value }">
                        <p v-if="value" class="py-4 px-2 truncate">{{ value | numberFormat(0) }}%</p>
                    </template>
                    <template v-slot:opportunities_plus_target_percent_reached="{ value }">
                        <p v-if="value" class="py-4 px-2 truncate">{{ value | numberFormat(0) }}%</p>
                    </template>

                </Table>
                <paginate v-if=areas.meta :meta=areas.meta @changePage="changePage"/>
                <PaginateTextInfo v-if="areas.meta" :meta="areas.meta" class="flex-auto text-center sm:hidden"/>
                <paginate-mobile :links="areas.links" @changePage="changePage" class="sm:hidden"/>
            </div>
        </SectionWrapper>
    </All>
</template>

<script>
import {All} from '@/api/components';
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/areas/Table";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import ShowMod from "@/pages/inside/sections/areas/modals/Show";
import NewMod from "@/pages/inside/sections/areas/modals/New";
import DestroyMod from "@/pages/inside/sections/areas/modals/Destroy";
import {actions, state} from "@/store";
import Header from "@/pages/inside/shared/datatables/Header";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
export default {
    name: "Areas",
    components:{
        All, SectionWrapper, Header, Paginate, PaginateMobile, PerPageSelector, Searcher, Table, ShowMod, NewMod, DestroyMod, PaginateTextInfo
    },
    data() {
        return {
            title: 'Áreas',
            entity: 'Área',
            loading: false,
            areasFilter: {
                scope: 'com',
                page: null,
                perPage: state.visibleRowsQtyInTable,
                field: 'name',
                direction: 'asc',
                text: null,
                from: this.$dayjs().startOf('year').format('YYYY-MM-DD'),
                to: this.$dayjs().endOf('year').format('YYYY-MM-DD')
            },
            fields,
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
    },
    mounted() {
        actions.setElSelected(false);
    },
    methods: {
        refreshDataTable(keepSelection = false) {
            if (!keepSelection) {
                actions.setElSelected(false);
            }
            this.$refs.areasRef.request();
        },

        changePage(page){
            this.areasFilter.page = page;
            this.refreshDataTable();
        },

        changePerPage(option){
            this.areasFilter.perPage = option;
            this.areasFilter.page = 1;
            this.refreshDataTable();
        },

        changeField(field){
            this.areasFilter.field = field;
            this.refreshDataTable();
        },

        changeDirection(direction){
            this.areasFilter.direction = direction;
            this.refreshDataTable();
        },

        changeText(text){
            this.areasFilter.page = null;
            this.areasFilter.text = text;
            this.refreshDataTable();
        },
    }
};

const fields = [
    {
        label: 'Área',
        key: 'name'
    },
    {
        label: 'Cursos',
        key: 'courses_count'
    },
    {
        label: 'Clientes',
        key: 'customers_size'
    },
    {
        label: 'Objetivo clientes proyectado',
        key: 'projected_customers_target'
    },
    {
        label: 'Clientes ratio',
        key: 'customers_target_percent_reached'
    },
    {
        label: 'Oportunidades plus',
        key: 'opportunities_plus_size'
    },
    {
        label: 'Objetivo oportunidades plus proyectado',
        key: 'projected_opportunities_plus_target'
    },
    {
        label: 'Oportunidades plus ratio',
        key: 'opportunities_plus_target_percent_reached'
    }
];
</script>

<style scoped>

</style>